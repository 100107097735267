// We are in an initializer file, so we MUST NOT import from too many dependencies
// import must be specific to avoid circular dependencies.
import { IsValidPhoneNumber } from '@react/helpers/phone_number';
import { Schema } from '@react/helpers/validation';

Schema.addMethod(Schema.string, 'phone', function format(countryCode, message) {
  return this.test('phone', message, (value, testContext) => {
    const { path, createError } = testContext;
    const isValid = !value || IsValidPhoneNumber(value, countryCode);

    if (!isValid) return createError(path, message);
    return true;
  });
});
